import React, { useState } from 'react';
import { Dropdown } from 'antd';
import PaymentDialog from 'components/Modals/CreatePaymentModal';

const InvoiceActions = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case 'PAYMENT':
        handleModalOpen();
        break;
      case 'REST_PASSWORD':
        handleModalClose();
        break;
      default:
        break;
    }
  };
  const actions = [
    {
      label: 'دفع',
      key: 'PAYMENT',
      icon: <i className="fas fa-check" />,
    },
    {
      label: 'طباعة',
      key: 'PRINT',
      icon: <i className="fas fa-print" />,
    },
  ];

  return (
    <>
      <Dropdown.Button trigger={['click']} menu={{ items: actions, onClick: handleMenuClick }}>
        خيارات
      </Dropdown.Button>
      {showModal && <PaymentDialog show={showModal} onHide={handleModalClose} />}
    </>
  );
};

export default InvoiceActions;

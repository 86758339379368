import dayjs from "dayjs";
import React, { useState } from "react";
import { DatePicker, Tabs } from "antd";
import { FormGroup, Input, Modal, Button, Row, Col } from "reactstrap";
import moment from "moment";
import PaymentService from "service/payment";
function PaymentDialog(props) {
  const [transActionDate, setTransActionDate] = useState();
  const [cachePaymentDate, setCachePaymentDate] = useState();
  const [bankName, setBankName] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [byUser, setByUser] = useState("");
  const [activeTap, setActiveTap] = useState(1);
  const [displayError, setDisplayError] = useState(false);

  const handleTabChange = (activeKey) => {
    setActiveTap(activeKey);
  };
  const handleClick = () => {
    if (activeTap === 1) {
      console.log("Transaction Payment");
      PaymentService.transactionPayment(transActionDate, bankName, bankAccount, transactionNumber)
        .then((success) => {
          if (success) {
            setDisplayError(false);
          } else {
            setDisplayError(true);
          }
        })
        .catch(() => {
          setDisplayError(true);
        });
    }
    if (activeTap === 2) {
      console.log("cache Payment");
      PaymentService.cachePayment(cachePaymentDate, byUser)
        .then((success) => {
          if (success) {
            setDisplayError(false);
          } else {
            setDisplayError(true);
          }
        })
        .catch(() => {
          setDisplayError(true);
        });
    }
  };
  const handleDate = (date, dateString) => {
    const selectedMoment = moment(dateString, "YYYY-MM-DD");
    const selectedDateOnly = selectedMoment.format("YYYY-MM-DD");
    if (activeTap === 1) setTransActionDate(selectedDateOnly);
    if (activeTap === 2) setCachePaymentDate(selectedDateOnly);
  };
  const { TabPane } = Tabs;
  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={props.show} toggle={props.onHide}>
        <div className="modal-body">
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab="حوالة بنكية" key="1" style={{ height: "180px" }} margin=" 0 32px 0 32px">
              <Row className="customer-list p-3">
                <Col lg="6">
                  <FormGroup>
                    <div>
                      <label className="form-control-label">التاريخ:</label>
                      <DatePicker
                        defaultValue={dayjs().add(1, "day")}
                        onChange={handleDate}
                        style={{ width: "180px" }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <Row>
                    <Col>
                      <label className="form-control-label">البنك:</label>
                    </Col>
                  </Row>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      setBankName(e.target.value);
                    }}>
                    <option value="ArabicBank">البنك العربي</option>
                    <option value="SafaBank">بنك الصفا</option>
                    <option value="PalistineBank">بنك فلسطين</option>
                  </select>
                </Col>
              </Row>
              <Row className="customer-list p-3">
                <Col lg="6">
                  <label className="form-control-label">الحساب</label>
                  <Input
                    className="form-control form-control-sm"
                    type="text"
                    onChange={(e) => {
                      setBankAccount(e.target.value);
                    }}
                  />
                </Col>
                <Col lg="6">
                  <label className="form-control-label">رقم الحوالة:</label>
                  <Input
                    className="form-control form-control-sm"
                    type="number"
                    onChange={(e) => {
                      setTransactionNumber(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              {displayError && (
                <div>
                  <p class="text-danger">{"خطأ في تعبئة البيانات  "}</p>
                </div>
              )}
            </TabPane>
            <TabPane tab="تسليم نقدي" key="2" style={{ height: "180px" }} margin=" 0 32px 0 32px">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <div className="pl-lg-4">
                      <label className="form-control-label">التاريخ:</label>
                    </div>
                    <DatePicker
                      defaultValue={dayjs().add(1, "day")}
                      onChange={handleDate}
                      style={{ width: "205px", marginRight: "10px" }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <label className="form-control-label">الموظف:</label>
                  <select
                    className="form-control form-control-sm mr-2"
                    onChange={(e) => {
                      setByUser(e.target.value);
                    }}>
                    <option value="1">Admin</option>
                    <option value="2">Admin</option>
                  </select>
                </Col>
              </Row>
              {displayError && (
                <div>
                  <p class="text-danger">{"خطأ في تعبئة البيانات  "}</p>
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>

        <div className="modal-footer">
          <Button color="secondary" data-dismiss="modal" type="button" onClick={props.onHide}>
            اغلاق
          </Button>
          <Button color="primary" type="button" onClick={handleClick}>
            حفظ
          </Button>
        </div>
      </Modal>
    </>
  );
}
export default PaymentDialog;

import InvoiceAPI from "api/invoice";

const InvoiceService = {
  getInvoiceList: function () {
    return InvoiceAPI.getInvoiceList();
  },
  createNewInvoice: function (orderId, orderItemIds) {
    return InvoiceAPI.createNewInvoice(orderId, orderItemIds);
  },
};

export default InvoiceService;

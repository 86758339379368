import OrganizationSelect from "components/Organization/OrganizationSelect";
import React from "react";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import OrderService from "service/order";

class CreateNewOrderModal extends React.Component {
  state = {
    organization: null,
    branch: [],
    isOpen: false,
    errorMessage: "",
    showError: false,
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      showError: false,
      organization: null,
    });
  };

  onBranchChange = (branchId) => {
    const branch = this.state.organization.branchList.find((i) => i.id == branchId);
    this.setState({ branch: branch });
  };

  handelSubmit = () => {
    this.setErrorMessage("");
    if (this.validateForm()) {
      OrderService.createNewOrder(this.state.organization.id, this.state.branch.id)
        .then(() => {
          this.props.onOrderCreated();
          this.closeModal();
        })
        .catch((error) => {
          this.setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
        });
    }
  };

  onOrganizationChange = (org) => {
    const branch = org?.branchList.filter((b) => b.status)[0];
    this.setState({ organization: org, branch: branch });
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  validateForm = () => {
    this.setState({ showError: true });
    let isValid = true;

    if (!this.state.branch | !this.state.organization) {
      isValid = false;
    }
    if (!isValid) {
      this.setErrorMessage("الرجاء ملء الحقول المطلوبة");
    }
    return isValid;
  };

  render() {
    const { organization, branch, errorMessage, showError } = this.state;

    const activeBranchList =
      organization && organization.branchList ? organization.branchList.filter((b) => b.status) : [];

    return (
      <>
        <Button color="success" type="button" onClick={() => this.openModal()} size="sm">
          انشاء طلب جديد
        </Button>

        <Modal className="modal-dialog-centered" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">انشاء طلب جديد</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <p>
                      الرجاء اختيار الشركة و الفرع المراد استلام الطرود منه لتتمكن من انشاء طلب جديد ثم ادخال جميع
                      الطرود المستلمة من الفرع
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <OrganizationSelect onChange={this.onOrganizationChange} showError={showError} />
                  </Col>
                  <Col lg="12 align-items-center">
                    <span className="font-weight-bold">الفرع:</span>

                    <select
                      className="form-control mt-2"
                      onChange={(e) => {
                        this.onBranchChange(e.target.value);
                      }}
                      disabled={!activeBranchList || activeBranchList.length === 0}>
                      {activeBranchList.map((b) => {
                        return (
                          <option value={b.id} key={b.id}>
                            {b.id} - {b.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="primary" type="button" onClick={() => this.handelSubmit()}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateNewOrderModal;

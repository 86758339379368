import React from "react";
import { Button, Modal, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";
import CustomerSearchModal from "./CustomerSearchModal";
import PackageService from "service/package";
import UserAvatar from "components/UserAvatar";
import OrganizationService from "service/organization";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import UtilityService from "service/utility";
import OrganizationSelect from "components/Organization/OrganizationSelect";
import FloatingLAbleInput from "components/FloatingLableInput/FloatingLAbleInput";

class CreateNewPackageModal extends React.Component {
  state = {
    organization: null,
    isOpen: false,
    errorMessage: "",
    customer: null,
    branch: null,
    packageDetails: "",
    customerNote: "",
    packagePrice: 0,
    packageWeight: 0,
    packageQuantity: 1,
    freeShipping: false,
    breakable: false,
    allowOpen: false,
    needsCooling: false,
    collected: false,
    showError: false,
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      customer: null,
      showError: false,
    });
    if (!UtilityService.isAdmin()) {
      OrganizationService.getCurrentOrganization(true).then((res) => {
        const activeBranchList = res.data.branchList.filter((b) => b.status);
        this.setState({ organization: res.data, branch: activeBranchList[0] });
      });
    }
    if (UtilityService.isAdmin()) {
      this.setState({ organization: null });
    }
  };

  onCustomerChange = (customer) => {
    this.setState({ customer: customer });
  };

  onBranchChange = (branchId) => {
    const branch = this.state.organization.branchList.find((i) => i.id == branchId);
    this.setState({ branch: branch });
  };

  resetCustomer = () => {
    this.setState({ customer: null });
  };

  handelSubmit = () => {
    this.setErrorMessage("");
    if (this.validateForm()) {
      PackageService.createNewPackage(
        this.state.branch.id,
        this.state.customer.customerId,
        this.state.customerNote,
        this.state.packageDetails,
        parseFloat(this.state.packagePrice),
        parseFloat(this.state.packageWeight),
        parseInt(this.state.packageQuantity),
        this.state.freeShipping,
        this.state.breakable,
        this.state.collected,
        this.state.allowOpen,
        this.state.needsCooling
      )
        .then(() => {
          this.props.onPackageCreated();
          this.closeModal();
        })
        .catch((error) => {
          this.setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
        });
    }
  };

  onOrganizationChange = (org) => {
    const branch = org?.branchList?.filter((b) => b.status)[0];
    this.setState({ organization: org, branch: branch });
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  validateForm = () => {
    this.setState({ showError: true });
    let isValid = true;
    if (UtilityService.isAdmin() && !this.state.branch) {
      isValid = false;
      this.setErrorMessage("الرجاء اختيار الشركة / الفرع");
    } else if (!this.state.customer) {
      isValid = false;
      this.setErrorMessage("الرجاء اختيار الزبون");
    } else if (!this.state.packageDetails) {
      isValid = false;
      this.setErrorMessage("الرجاء ملء الحقول المطلوبة");
    }
    return isValid;
  };

  render() {
    const {
      organization,
      branch,
      errorMessage,
      freeShipping,
      breakable,
      collected,
      allowOpen,
      needsCooling,
      customer,
      showError,
      packageDetails,
      packageQuantity,
    } = this.state;
    const activeBranchList = organization?.branchList.filter((b) => b.status);

    return (
      <div className={this.props.className}>
        <Button color="success" type="button" onClick={() => this.openModal()} size="sm">
          ادخال طرد جديد <i className="fas fa-plus"></i>
        </Button>

        <Modal
          className="modal-dialog-centered modal-lg modal-dialog-scrollable"
          isOpen={this.state.isOpen}
          toggle={() => this.closeModal()}>
          <div className="modal-body p-0">
            <Form>
              <Row className="m-0">
                <Col col="6" className="p-0">
                  <h4 className="mb-4">معلومات التاجر</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6" className="mb-2 mb-md-0">
                        {UtilityService.isAdmin() && (
                          <OrganizationSelect
                            inline={true}
                            onChange={this.onOrganizationChange}
                            showError={showError}
                          />
                        )}
                        {!UtilityService.isAdmin() && (
                          <div className="d-flex align-items-center h-100">
                            <span className="font-weight-bold">اسم التاجر:</span>
                            <span className="mx-2">{organization?.name}</span>
                          </div>
                        )}
                      </Col>
                      <Col lg="6 d-flex align-items-center">
                        <span className="font-weight-bold">الفرع:</span>
                        {(UtilityService.isAdmin() || (activeBranchList && activeBranchList.length > 1)) && (
                          <select
                            className="form-control mr-2"
                            onChange={(e) => {
                              this.onBranchChange(e.target.value);
                            }}
                            disabled={!activeBranchList || activeBranchList.length === 0}>
                            {activeBranchList &&
                              activeBranchList.map((b) => {
                                return (
                                  <option value={b.id} key={b.id}>
                                    {b.id} - {b.name}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                        {!UtilityService.isAdmin() && activeBranchList && activeBranchList.length === 1 && (
                          <span className="mx-2">{branch?.name}</span>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="12">
                        <span className="font-weight-bold">العنوان:</span>
                        {branch && (
                          <span className="mx-2">
                            {UtilityService.renderCity(branch?.city)}, {branch?.address}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h4 className="mb-4">معلومات الزبون</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <Col col="6">
                        <FloatingLAbleInput lable="اسم المستلم" onChange={() => {}} />
                        <FloatingLAbleInput lable="عنوان المستلم" onChange={() => {}} />
                        <FloatingLAbleInput lable="العنوان بالتفصيل" onChange={() => {}} />
                      </Col>
                      <Col col="6">
                        <FloatingLAbleInput lable="رقم الموبايل" onChange={() => {}} />
                        <FloatingLAbleInput lable="رقم موبايل ثاني" onChange={() => {}} />
                      </Col>
                    </Row>
                  </div>
                  <Row className="mt-3">
                    <Col lg="12">
                      <FormGroup className="mb-0">
                        <label className="form-control-label">ملاحظات الزبون:</label>
                        <textarea
                          className="form-control form-control-sm"
                          name="postContent"
                          rows={2}
                          cols={80}
                          onChange={(e) => {
                            this.setState({ customerNote: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h4 className="mb-4">معلومات الطرد</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">وصف الطرد:</label>
                          <Input
                            className={`form-control form-control ${showError && !packageDetails ? "is-invalid" : ""}`}
                            type="text"
                            onChange={(e) => {
                              this.setState({ packageDetails: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" sm="6" md="6" lg="3">
                        <FormGroup>
                          <label className="form-control-label">الكمية:</label>
                          <Input
                            className="form-control form-control-sm"
                            type="number"
                            placeholder="0"
                            min="0"
                            value={packageQuantity}
                            onChange={(e) => {
                              this.setState({ packageQuantity: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6" lg="3">
                        <FormGroup>
                          <label className="form-control-label">السعر الكلي لطرد:</label>
                          <Input
                            className="form-control form-control-sm"
                            type="number"
                            placeholder="0.00"
                            min="0"
                            onChange={(e) => {
                              this.setState({ packagePrice: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6" lg="3">
                        <FormGroup>
                          <label className="form-control-label">الوزن الاجمالي لطرد:</label>
                          <Input
                            className="form-control form-control-sm"
                            type="number"
                            placeholder="0.00"
                            min="0"
                            onChange={(e) => {
                              this.setState({ packageWeight: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6" lg="3">
                        <FormGroup>
                          <label className="form-control-label">تاريخ الشحن:</label>
                          <div>
                            <DatePicker className="w-100" defaultValue={dayjs().add(1, "day")} />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col col="6" className="p-0" style={{ backgroundColor: "#fff6e9" }}>
                  <Row className="m-0">
                    <Col xs="6" sm="6" md="6" lg="4">
                      <FormGroup check>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="collected"
                            type="checkbox"
                            value={collected}
                            onChange={(e) => this.setState({ collected: e.target.checked })}
                          />
                          <label className="custom-control-label" htmlFor="collected">
                            تم التحصيل مسبقا
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="4">
                      <FormGroup check>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="freeShipping"
                            type="checkbox"
                            value={freeShipping}
                            onChange={(e) => this.setState({ freeShipping: e.target.checked })}
                          />
                          <label className="custom-control-label" htmlFor="freeShipping">
                            شحن مجاني
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="4">
                      <FormGroup check>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="breakable"
                            type="checkbox"
                            value={breakable}
                            onChange={(e) => this.setState({ breakable: e.target.checked })}
                          />
                          <label className="custom-control-label" htmlFor="breakable">
                            الطرد قابل للكسر
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="4">
                      <FormGroup check>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="allowOpen"
                            type="checkbox"
                            value={allowOpen}
                            onChange={(e) => this.setState({ allowOpen: e.target.checked })}
                          />
                          <label className="custom-control-label" htmlFor="allowOpen">
                            السماح بفتح الطرد
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="4">
                      <FormGroup check>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="needsCooling"
                            type="checkbox"
                            value={needsCooling}
                            onChange={(e) => this.setState({ needsCooling: e.target.checked })}
                          />
                          <label className="custom-control-label" htmlFor="needsCooling">
                            يحتاج الى تبريد
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CreateNewPackageModal;

import { Card, CardBody, CardHeader, Row } from "reactstrap";
import { useEffect, useState } from "react";
import OrganizationService from "service/organization";

const OrganizationInfo = (props) => {
  const [organization, setOrganization] = useState({});

  useEffect(() => {
    populateOrganizationDetails();
  }, [props]);

  const populateOrganizationDetails = () => {
    if (props.organizationId)
      OrganizationService.getOrganizationDetails(props.organizationId).then((res) => setOrganization(res.data));
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">معلومات التاجر</h3>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          {organization && (
            <div>
              <p className="m-0 font-weight-bold">
                اسم الشركة / التاجر: <span className="font-weight-normal">{organization.name}</span>
              </p>

              <p className="m-0 mt-2 font-weight-bold">
                تلفون : <span className="font-weight-normal">{organization.phone}</span>
              </p>

              <p className="m-0 mt-2 font-weight-bold">
                العنوان : <span className="font-weight-normal">{organization.address}</span>
              </p>
            </div>
          )}
          {!organization && <p>لا يوجد بيانات</p>}
        </CardBody>
      </Card>
    </>
  );
};

export default OrganizationInfo;

import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import CollectionTable from "./CollectionTable";
import CollectionService from "service/collection";

const CollectionList = (props) => {
  const [collectionList, setCollectionList] = useState([]);

  useEffect(() => {
    populateCollection();
  }, [props]);

  const populateCollection = () => {
    CollectionService.getCollectionList().then((res) => setCollectionList(res.data));
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        {collectionList && <CollectionTable data={collectionList} onDataChange={populateCollection} />}
        <p></p>
      </Container>
    </>
  );
};

export default CollectionList;

import { Card, CardBody, CardFooter, CardHeader, Container, Row, Table } from "reactstrap";

import { useEffect, useState } from "react";
import InvoiceService from "service/invoice";
import UtilityService from "service/utility";
import ListPagination from "views/PaginationList";
import InvoiceActions from "./InvoiceActions";

const InvoiceList = (props) => {
  const [invoiceList, setInvoiceList] = useState([]);

  useEffect(() => {
    populateInvoiceList();
  }, [props]);

  const populateInvoiceList = () => {
    InvoiceService.getInvoiceList().then((res) => {
      setInvoiceList(res.data.invoices);
    });
  };
  const itemPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);

  const handleCurrentPage = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">الفواتير</h3>
                  </div>
                  <div className="col text-left">
                    <i className="fas fa-redo" role="button" onClick={populateInvoiceList}></i>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">#</th>
                        <th>الشركة</th>
                        <th>الطلب</th>
                        <th>القيمة</th>
                        <th>تاريخ الانشاء</th>
                        <th>الحالة</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceList &&
                        invoiceList.map(function (invoice, index) {
                          return (
                            <tr key={invoice.id}>
                              <th className="px-4">{invoice.id}</th>
                              <th>123456</th>
                              <td>152</td>
                              <td>{UtilityService.formatPrice(invoice.totalAmount)}</td>
                              <td>{UtilityService.formatTimestamp(invoice.created)}</td>
                              <td>مدفوعة</td>
                              <th>
                                <InvoiceActions invoice={{ status: "" }} />
                              </th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ListPagination
                    pageSize={itemPerPage}
                    totalResult={invoiceList.length}
                    setCurrentPage={handleCurrentPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default InvoiceList;

import { OrderAPI } from "api/order";

const OrderService = {
  getOrdersList: function () {
    return OrderAPI.getOrdersList();
  },
  getOrdersDetails: function (orderId) {
    return OrderAPI.getOrdersDetails(orderId);
  },
  createNewOrder: function (organizationId, branchId) {
    return OrderAPI.createNewOrder(organizationId, branchId);
  },
  addPackageToOrder: function (orderId, packageId) {
    return OrderAPI.addPackageToOrder(orderId, packageId);
  },
  deleteOrderItem: function (orderItemId) {
    return OrderAPI.deleteOrderItem(orderItemId);
  },
  calculateOrder: function (orderId) {
    return OrderAPI.calculateOrder(orderId);
  },
  closeOrder: function (orderId) {
    return OrderAPI.closeOrder(orderId);
  },
};

export default OrderService;

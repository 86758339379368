import CreateNewInvoiceModal from "components/Modals/CreateNewInvoiceModal";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";
import UtilityService from "service/utility";

const OrderIInvoices = (props) => {
  const { order, onRefresh } = props;
  const invoices = order?.invoices;

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">الفواتير</h3>
            </div>
            <div className="col text-left d-flex justify-content-end align-items-center">
              <CreateNewInvoiceModal order={order} onInvoiceCreated={onRefresh} />
            </div>
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="m-0">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th className="px-4">رقم الفاتورة</th>
                  <th>تاريخ الانشاء</th>
                  <th className="text-center">مجموع الفاتورة</th>
                  <th className="text-center">عدد الطرود</th>
                  <th>الحالة</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {invoices &&
                  invoices.map(function (invoice, i) {
                    return (
                      <tr key={invoice.id}>
                        <td className="px-4">{invoice.id}</td>
                        <td>{UtilityService.formatTimestamp(invoice.created)}</td>
                        <td className="text-center">{UtilityService.formatPrice(invoice.totalAmount)}</td>
                        <td className="text-center">{invoice.totalPackage || 0}</td>
                        <td>{UtilityService.renderStatusMessage("INVOICE", invoice.status)}</td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <i className="fas fa-download display-4 mx-3" role="button"></i>
                            <i className="fas fa-print display-4" role="button"></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {(!invoices || invoices.length == 0) && (
                  <tr>
                    <td col="4" className="px-4">
                      لا يوجد اي فواتير لهذا الطلب.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default OrderIInvoices;

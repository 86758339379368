import { PaymentAPI } from 'api/payment';

const PaymentService = {
  getPaymentList: function () {
    return PaymentAPI.getPaymentList();
  },
  cachePayment: function (date, byUser) {
    return PaymentAPI.cachePayment({
      date: date,
      byUser: byUser,
    });
  },
  transactionPayment: function (date, bankName, bankAccount, transactionNumber) {
    return PaymentAPI.transactionPayment({
      date: date,
      bankName: bankName,
      bankAccount: bankAccount,
      transactionNumber: transactionNumber,
    });
  },
};

export default PaymentService;

import React from "react";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import InvoiceService from "service/invoice";
import UtilityService from "service/utility";

class CreateNewInvoiceModal extends React.Component {
  state = {
    isOpen: false,
    errorMessage: "",
    showError: false,
    totalPackage: 0,
    totalCollection: 0.0,
    orderItems: [],
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      showError: false,
      orderItems: this.props.order.items,
    });
  };

  handelSubmit = () => {
    this.setErrorMessage("");
    if (this.validateForm()) {
      InvoiceService.createNewInvoice(this.props.order.id, this.getSelectedItemsIds())
        .then(() => {
          this.props.onInvoiceCreated();
          this.closeModal();
        })
        .catch((error) => {
          this.setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
        });
    }
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  validateForm = () => {
    this.setState({ showError: true });
    let isValid = true;

    if (this.getSelectedItemsIds().length === 0) {
      isValid = false;
    }
    if (!isValid) {
      this.setErrorMessage("الرجاء اختيار طرد واحد على الاقل");
    }
    return isValid;
  };

  getSelectedItemsIds = () => {
    return this.state.orderItems.filter((i) => i.selected).map((i) => i.id);
  };

  renderPackageListItem = (item) => {
    return (
      <div key={item.id} className="d-flex justify-content-between align-items-center mb-2 pb-2 border-bottom">
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            value={item.id}
            checked={item.selected}
            id={"flexCheckDefault-" + item.id}
            onChange={(event) => this.handleCheckboxChange(event.target.checked, item.id)}
          />
          <label className="form-check-label mx-4" htmlFor={"flexCheckDefault-" + item.id}>
            <div>
              <p className="m-0 font-weight-bold">{UtilityService.formatPackageId(item.package?.id)}</p>
              <p className="m-0">{item.package?.description}</p>
            </div>
          </label>
        </div>
        <div>{UtilityService.formatPrice(item.package?.price)}</div>
      </div>
    );
  };

  handleCheckboxChange = (checked, itemId) => {
    this.setState((prevState) => {
      const updatedOrderItems = prevState.orderItems.map((item) =>
        item.id === itemId ? { ...item, selected: checked } : item
      );

      let totalPackage = 0,
        totalCollection = 0;

      updatedOrderItems.forEach((item) => {
        if (item.selected) {
          totalPackage += 1;
          totalCollection += item.package.price;
        }
      });

      return { orderItems: updatedOrderItems, totalCollection: totalCollection, totalPackage: totalPackage };
    });
  };

  render() {
    const { errorMessage, orderItems } = this.state;

    return (
      <>
        <Button color="success" type="button" onClick={() => this.openModal()} size="sm">
          انشاء فاتورة جديدة
        </Button>

        <Modal className="modal-dialog-centered" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom pb-2">
            <h3 className="modal-title">انشاء فاتورة جديدة</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <Row>
                <Col lg="12">
                  <p>الرجاء اختيار جميع الطرود المراد ادراجها في هذه الفاتورة</p>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="">{orderItems && orderItems.map(this.renderPackageListItem)}</div>
                </Col>
              </Row>
              <Row className="bg-primary mx-0 rounded py-2">
                <Col lg="12">
                  <div className="d-flex justify-content-between align-items-center text-white">
                    <p className="mb-0 font-weight-bold">مجموع الطرود:</p>
                    <p className="mb-0 font-weight-bold">{this.state.totalPackage}</p>
                  </div>
                </Col>
                <Col lg="12">
                  <div className="d-flex justify-content-between align-items-center text-white">
                    <p className="mb-0 font-weight-bold">مجموع التحصيل:</p>
                    <p className="mb-0 font-weight-bold">{UtilityService.formatPrice(this.state.totalCollection)}</p>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="modal-footer border-top p-2">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="primary" type="button" onClick={() => this.handelSubmit()}>
              انشاء
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateNewInvoiceModal;

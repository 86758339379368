import CreateNewOrderModal from "components/Modals/CreateNewOrderModal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, Container, Row, Table } from "reactstrap";
import OrderService from "service/order";
import UtilityService from "service/utility";
import ListPagination from "views/PaginationList";

const OrdersList = (props) => {
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    populateOrderList();
  }, [props]);

  const populateOrderList = () => {
    OrderService.getOrdersList().then((res) => setOrderList(res.data.orders));
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleCurrentPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">الطلبات</h3>
                  </div>
                  <div className="col text-left">
                    <i className="fas fa-redo mx-4" role="button" onClick={populateOrderList}></i>
                    <CreateNewOrderModal onOrderCreated={populateOrderList} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">#</th>
                        <th>تايخ الادخال</th>
                        {UtilityService.isAdmin() && <th>الشركة</th>}
                        <th className="text-center">عدد الزبائن</th>
                        <th className="text-center">عدد الطرود</th>
                        <th className="text-center">مجموع التحصيل</th>
                        <th>الحالة</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderList &&
                        orderList.map(function (order) {
                          return (
                            <tr key={order.id}>
                              <td className="px-4">
                                <Link className="text-dark text-underline" to={"/orders/" + order.id}>
                                  {order.id}
                                </Link>
                              </td>
                              <td>{UtilityService.formatTimestamp(order.created)}</td>
                              {UtilityService.isAdmin() && (
                                <td>
                                  {order.organization.name}
                                  <br /> {order.organization.branch.name} ({order.organization.branch.phone})
                                  <br /> {order.organization.branch.address}
                                </td>
                              )}
                              <td className="text-center">{order.totalCustomers || 0}</td>
                              <td className="text-center">{order.totalPackages || 0}</td>
                              <td className="text-center">{UtilityService.formatPrice(order.totalCollections)}</td>
                              <td>{UtilityService.renderStatusMessage("ORDER", order.status)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ListPagination
                    pageSize={itemsPerPage}
                    totalResult={orderList?.totalOrders}
                    setCurrentPage={handleCurrentPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrdersList;

import { Card, CardBody, CardHeader, Row } from "reactstrap";

const DriverInfo = (props) => {
  const { order } = props;
  const driver = order.driver;

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">معلومات السائق المستلم</h3>
            </div>
          </Row>
        </CardHeader>
        <CardBody className="p-">
          {driver && (
            <div>
              <p className="m-1 d-flex justify-content-between font-weight-bold">
                <span className="font-weight-bold">اسم السائق:</span>{" "}
                <span>
                  {driver.firstName} {driver.middleName} {driver.lastName}
                </span>
              </p>
              <p className="m-1 d-flex justify-content-between font-weight-bold">
                <span className="font-weight-bold"> رقم الموبايل:</span> <span> {driver.mobile}</span>
              </p>
            </div>
          )}
          {!driver && <p>لا يوجد بيانات</p>}
        </CardBody>
      </Card>
    </>
  );
};

export default DriverInfo;

import { Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup } from "reactstrap";
import SearchResultModal from "./SearchResultModal";
import { useEffect, useRef, useState } from "react";
import SearchService from "service/search";

const defaultResult = { customers: [], packages: [], orders: [], invoices: [] };

const SearchBox = () => {
  const [searchResult, setSearchResult] = useState(defaultResult);
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    if (searchTerm.trim() !== "") {
      SearchService.search(searchTerm).then((res) => {
        setSearchResult(res.data);
      });
      setOpen(true);
    } else {
      setSearchResult(defaultResult);
      setOpen(false);
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto" ref={wrapperRef}>
      <FormGroup className="mb-0">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fas fa-search" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="بحث عن طرد, زبون, طلب, ..."
            type="text"
            onClick={(e) => setOpen(e.target.value != "")}
            onChange={handleSearch}
          />
        </InputGroup>
      </FormGroup>
      {searchResult && <SearchResultModal result={searchResult} open={isOpen} />}
    </div>
  );
};

export default SearchBox;

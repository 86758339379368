import { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const ListPagination = (props) => {
  const { pageSize, totalResult } = props;
  const pageCount = Math.ceil(totalResult / pageSize);
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageChange = (pageNumber) => {
    props.setCurrentPage(pageNumber);
    setPageNumber(pageNumber);
  };
  return (
    <>
      {pageCount > 1 && pageSize && (
        <Pagination
          className='pagination justify-content-end mb-0'
          listClassName='justify-content-end mb-0'
        >
          <PaginationItem disabled={pageNumber === 1}>
            <PaginationLink
              previous
              onClick={() => handlePageChange(pageNumber - 1)}
            />
          </PaginationItem>

          {Array.from({ length: pageCount }, (_, index) => (
            <PaginationItem active={index + 1 === pageNumber} key={index}>
              <PaginationLink onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}

          <PaginationItem disabled={pageNumber === pageCount}>
            <PaginationLink
              next
              onClick={() => handlePageChange(pageNumber + 1)}
            />
          </PaginationItem>
        </Pagination>
      )}
    </>
  );
};

export default ListPagination;

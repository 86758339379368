import { CollectionAPI } from "api/collection";

const CollectionService = {
  createInvoice: function (packageIds) {
    return CollectionAPI.createInvoice(packageIds);
  },
  getCollectionList: function () {
    return CollectionAPI.getCollectionList();
  },
  getCollectionDetails : function (collectionId){
    return CollectionAPI.getCollectionDetails(collectionId);
  },
};

export default CollectionService;

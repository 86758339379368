import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import CollectionService from "service/collection";

import CollectionItems from "./CollectionItems";
import OrganizationInfo from "./OrganizationInfo";
import Summary from "./Summary";

const CollectionDetails = (props) => {
  const [collection, setCollection] = useState({});

  const { id } = useParams();

  useEffect(() => {
    populateCollectionDetails();
  }, [props]);

  const populateCollectionDetails = () => {
    CollectionService.getCollectionDetails(id).then((res) => {
      setCollection(res.data);
    });
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">تفاصيل كشف التحصيل</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {collection && collection.items && (
                  <Row className="m-0">
                    <Col lg="4" className="p-4">
                      <OrganizationInfo organizationId={collection.organizationId} />
                    </Col>
                    <Col lg="4" className="p-4">
                      <Summary
                        totalCollections={collection.totalCollections}
                        totalCost={collection.totalCost}
                        amount={collection.amount}
                      />
                    </Col>
                    <Col lg="12" className="p-4">
                      <CollectionItems data={collection.items} />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CollectionDetails;

import APIClient from "./axios.js";
import {
  GET_ORDERS_LIST_API_URL,
  GET_ORDERS_DETAILS_API_URL,
  CREATE_NEW_ORDER_API_URL,
  ADD_PACKAGE_TO_ORDER_API_URL,
  DELETE_ORDER_ITEM_API_URL,
  CALCULATE_ORDER_API_URL,
  CLOSE_ORDER_API_URL,
} from "../variables/constant";

export const OrderAPI = {
  getOrdersList: function () {
    return APIClient.get(GET_ORDERS_LIST_API_URL);
  },
  getOrdersDetails: function (orderId) {
    return APIClient.get(GET_ORDERS_DETAILS_API_URL.replace("{orderId}", orderId));
  },
  createNewOrder: function (organizationId, branchId) {
    return APIClient.post(CREATE_NEW_ORDER_API_URL, { organizationId, branchId });
  },
  addPackageToOrder: function (orderId, packageId) {
    return APIClient.post(ADD_PACKAGE_TO_ORDER_API_URL, { orderId, packageId });
  },
  deleteOrderItem: function (orderItemId) {
    return APIClient.post(DELETE_ORDER_ITEM_API_URL, { orderItemId });
  },
  calculateOrder: function (orderId) {
    return APIClient.post(CALCULATE_ORDER_API_URL.replace("{orderId}", orderId));
  },
  closeOrder: function (orderId) {
    return APIClient.post(CLOSE_ORDER_API_URL.replace("{orderId}", orderId));
  },
};

import APIClient from "./axios.js";
import {
  CREAT_COLLECTION_INVOICE_API_URL,
  GET_COLLECTION_LIST_API_URL,
  GET_COLLECTION_DETAILS_API_URL,
} from "../variables/constant";

export const CollectionAPI = {
  createInvoice: function (packageIds) {
    return APIClient.post(CREAT_COLLECTION_INVOICE_API_URL, { packageIds: packageIds });
  },
  getCollectionList: function () {
    return APIClient.get(GET_COLLECTION_LIST_API_URL);
  },
  getCollectionDetails: function (collectionId) {
    return APIClient.get(GET_COLLECTION_DETAILS_API_URL.replace("{collectionId}", collectionId));
  },
};

import APIClient from "./axios.js";
import { GET_INVOICE_LIST_API_URL, CREATE_NEW_INVOICE_API_URL } from "../variables/constant";

const InvoiceAPI = {
  getInvoiceList: function () {
    return APIClient.get(GET_INVOICE_LIST_API_URL);
  },
  createNewInvoice: function (orderId, orderItemIds) {
    return APIClient.post(CREATE_NEW_INVOICE_API_URL, { orderId, orderItemIds });
  },
};

export default InvoiceAPI;

export const IBIZA_INFO = {
  TAX_ID: "562788927",
  ADDRESS: "نابلس, دوار قوصين, الشارع الرئيسي",
  PHONE: "0599705484",
};

const API_PREFEX = "/v1/admin";

export const PACKAGE_STATUS_LIST = ["N", "M", "J", "P", "A", "G", "E", "O", "V", "R", "C", "X", "D", "L", "Z", "H", "S"];

export const CURRENCY_SYMBOL = "₪";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_API_URL = "/v1/admin/auth/login";

export const GET_CURRENT_USER_API_URL = "/v1/admin/user/current";

export const GET_CURRENT_USER_PRIMARY_ADDRESS_API_URL = "/v1/admin/user/current/address/default";

export const UPDATE_CURRENT_USER_PROFILE_API_URL = "/v1/admin/user/current/update";

export const CREATE_NEW_PACKAGE_API_URL = "/v1/admin/package/new";

export const CUSTOMER_SEARCH_API_URL = "/v1/admin/customer/search";

export const GET_PACKAGE_HISTORY_LIST_API_URL = "v1/admin/package/history";

export const CRATE_NEW_CUSTOMER_API_URL = "/v1/admin/customer/new";

export const GET_AREA_BY_CITY_API_URL = "/v1/area/bycity/{city}";

export const GET_ALL_AREA_API_URL = "/v1/area/list";

export const GET_CUSTOMER_STATISTIC_API_URL = "/v1/admin/statistic/customer";

export const GET_COLLECTION_STATISTIC_API_URL = "/v1/admin/statistic/collection";

export const GET_PACKAGE_STATISTIC_API_URL = "/v1/admin/statistic/package";

export const GET_SUMMARY_STATISTIC_API_URL = "/v1/admin/statistic/summary";

export const GET_PACKAGE_BY_CITY_STATISTIC_API_URL = "/v1/admin/statistic/package";

export const GET_ACTIVE_PACKAGE_LIST_API_URL = "/v1/admin/package/active";

export const GET_PACKAGE_LIST_API_URL = "/v1/admin/package/list";

export const ORGANIZATION_CREATE_NEW_BRANCH_API_URL = "/v1/admin/organization/branch/new";

export const ORGANIZATION_CREATE_NEW_USER_API_URL = "/v1/admin/organization/users/new";

export const CREATE_NEW_ORGANIZATION_API_URL = "/v1/admin/organization/new";

export const ORGANIZATION_UPDATE_BRANCH_API_URL = "/v1/admin/organization/branch/{branchId}/update";

export const UPDATE_ORGANIZATION_API_URL = "/v1/admin/organization/{organizationId}/update";

export const GET_CURRENT_ORGANIZATION_API_URL = "/v1/admin/organization/current";

export const GET_ORGANIZATION_DETAILS_API_URL = "/v1/admin/organization/{organizationId}";

export const GET_ALL_ORGANIZATION_API_URL = "/v1/admin/organization/list";

export const GET_CURRENT_ORGANIZATION_BRANCH_LIST_API_URL = "/v1/admin/organization/branch";

export const SEARCH_API_URL = "/v1/search";

export const GET_CURRENT_ORGANIZATION_USER_LIST_API_URL = "/v1/admin/organization/users";

export const ORGANIZATION_UPDATE_USER_API_URL = "/v1/admin/organization/users/{userId}/update";

export const ORGANIZATION_RESET_USER_PASSWORD_API_URL = "/v1/admin/organization/users/{userId}/reset-password";

export const CACHE_PAYMENT_METHOD_API_URL = "";

export const TRANSACTION_PAYMENT_METHOD_API_URL = "";

export const GET_CUSTOMER_LIST_API_URL = "/v1/admin/customer/list";

export const UPDATE_PACKAGE_API_URL = "/v1/admin/package/{packageId}/update";

export const ASSIGN_DRIVER_TO_PACKAGE_API_URL = "/v1/admin/package/{packageId}/assign-driver";

export const LINK_PACKAGE_TO_EXTERNAL_SYSTEM_API_URL = "/v1/admin/package/{packageId}/external/link";

export const GET_ORDERS_LIST_API_URL = "/v1/admin/order/list";

export const GET_ORDERS_DETAILS_API_URL = "/v1/admin/order/{orderId}";

export const CALCULATE_ORDER_API_URL = "/v1/admin/order/{orderId}/calculate";

export const CLOSE_ORDER_API_URL = "/v1/admin/order/{orderId}/close";

export const CREATE_NEW_ORDER_API_URL = "/v1/admin/order/new";

export const ADD_PACKAGE_TO_ORDER_API_URL = "/v1/admin/order/package/add";

export const DELETE_ORDER_ITEM_API_URL = "/v1/admin/order/package/remove";

export const GET_ALL_DRIVERS_API_URL = "/v1/admin/driver/list";

export const UPDATE_DRIVER_API_URL = "/v1/admin/driver/{userId}/update";

export const CREATE_NEW_DRIVER_API_URL = "/v1/admin/driver/new";

export const GET_PACKAGE_DETAILS_API_URL = "/v1/admin/package/{packageId}";

export const GET_INVOICE_LIST_API_URL = "/v1/admin/invoice/list";

export const CREATE_NEW_INVOICE_API_URL = "/v1/admin/invoice/new";

export const GET_PAYMENT_LIST_API_URL = "/v1/payment/list";

export const RESET_PASSWORD_API_URL = "/v1/admin/auth/reset-password";

export const UPDATE_CURRENT_USER_PASSWORD_API_URL = "/v1/user/current/update-password";

export const UPDATE_CURRENT_USER_PRIMARY_ADDRESS_URL = "/v1/user/current/update-address";

export const GET_ALL_PRIICE_LIST_API_URL = "/v1/admin/price-list/list";

export const RESOLVE_PRIICE_LIST_API_URL =
  "/v1/admin/price-list/resolve?organizationId={organizationId}&areaCode={areaCode}";

export const GET_ALL_CONFIG_API_URL = "/v1/admin/config/list";

export const SAVE_CONFIG_API_URL = "/v1/admin/config/save";

export const DELETE_CONFIG_API_URL = "/v1/admin/config/delete";

export const REFRESH_CONFIG_API_URL = "/v1/admin/config/refresh";

export const GET_MESSAGE_SUMMARY_API_URL = "/v1/admin/message/summary";

export const GET_MESSAGE_LIST_API_URL = "/v1/admin/message/list";

export const SEND_MESSAGE_API_URL = "/v1/admin/message/send";

export const GET_ALL_LOGS_FILE_API_URL = "/v1/admin/logs/list";

export const GET_LOGS_FILE_CONTENT_API_URL = "/v1/admin/logs/content";

export const DOWNLOAD_LOGS_FILE_API_URL = "/v1/admin/logs/download";

export const PRINT_PACKAGE_BILL_API_URL = "/v1/admin/package/print";

export const CREAT_COLLECTION_INVOICE_API_URL = "/v1/admin/collection/new";

export const GET_PACKAGE_SUMMARY_URL = "/v1/admin/package/summary";

export const GET_COLLECTION_LIST_API_URL = "/v1/admin/collection/list";

export const GET_COLLECTION_DETAILS_API_URL = "/v1/admin/collection/{collectionId}";

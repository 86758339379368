import APIClient from './axios.js';
import { CACHE_PAYMENT_METHOD_API_URL, TRANSACTION_PAYMENT_METHOD_API_URL, GET_PAYMENT_LIST_API_URL } from '../variables/constant';

export const PaymentAPI = {
  getPaymentList: function () {
    return APIClient.get(GET_PAYMENT_LIST_API_URL);
  },
  cachePayment: function (data) {
    return APIClient.post(CACHE_PAYMENT_METHOD_API_URL, data);
  },
  transactionPayment: function (data) {
    return APIClient.post(TRANSACTION_PAYMENT_METHOD_API_URL, data);
  },
};

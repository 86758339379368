// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import UserAvatar from "./UserAvatar";
import UtilityService from "service/utility";

const UserDisplay = (props) => {
  return (
    <div className="user-display avatar-group">
      <a className="d-flex align-items-center" href="#" onClick={(e) => e.preventDefault()}>
        <UserAvatar photo={props.user?.photo} />
        <span className="mx-2 text-gray">
          <span className="text-dark">
            {props.user?.firstName} {props.user?.middleName} {props.user?.lastName}
            {<span className="badge badge-primary text-dark">{props.user?.mobile}</span>}
          </span>
          <br /> {props.address && UtilityService.formatAddress(props.address, props.fullAddress)}
        </span>
      </a>
    </div>
  );
};

UserDisplay.defaultProps = {
  routes: [{}],
};

UserDisplay.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    image: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
};

export default UserDisplay;

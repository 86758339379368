import { useMemo } from "react";
import UtilityService from "service/utility";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import { ListItemIcon, MenuItem } from "@mui/material";
import { Delete  } from "@mui/icons-material";
import { useState, useRef } from "react";
import DeletePackageFromInvoiceModel from "components/Modals/DeletePackageFromInvoiceModel";
const Table = (props) => {
  const { data } = props;
  const [isChangeModalOpen, setChangeModalOpen] = useState(false);
  const editingRow = useRef(null);

  const handleDeletePackageFromInvoice = () => {
    //logic for delete 
    setChangeModalOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: " رقم الطرد",
        Cell: ({ cell }) => UtilityService.formatPackageId(cell.getValue()),
      },
      {
        accessorKey: "packageStatus",
        header: "الحالة",
        Cell: ({ cell }) => UtilityService.renderStatusMessage("PACKAGE", cell.getValue()),
      },
      {
        accessorKey: "csutomerName",
        header: "اسم الزبون",
      },
      {
        accessorKey: "csutomerMobile",
        header: "رقم المستقبل",
      },
      {
        accessorKey: "address",
        header: "العنوان",
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.pacakgeDate), //convert to date for sorting and filtering
        header: "التاريخ ",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // convert back to string for display
      },
      {
        accessorKey: "totalCollections",
        header: "التحصيل",
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "cost",
        header: "التوصيل",
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        header: "تحصيل التاجر",
        Cell: ({ cell }) => {
          const requiredForMerchant = cell.row.original.totalCollections - cell.row.original.cost;
          return requiredForMerchant.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          });
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    columnResizeDirection: "rtl",
    enableRowSelection: false,
    enableRowActions: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    columnFilterDisplayMode: "popover",
    enablePagination: true,
    positionToolbarAlertBanner: "head-overlay",
    enableTopToolbar: false,
    renderRowActionMenuItems: ({closeMenu,  row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          setChangeModalOpen(true);
          editingRow.current = { row};
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
        <Delete  />
        </ListItemIcon>
            حذف
      </MenuItem>,
    ],

  });

  return (
    <div>
      <h3 className="mb-3 mr-3"> الطرود</h3> 
      <MaterialReactTable table={table} />
      {editingRow.current && (<DeletePackageFromInvoiceModel
              isOpen={isChangeModalOpen}
              onClose={() => setChangeModalOpen(false)}
              onConfirmDeletion= {handleDeletePackageFromInvoice}
              packageId= {editingRow.current.row.original.id}
        />)};
    </div>
  );
};

const CollectionItems = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CollectionItems;

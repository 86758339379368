import { useMemo, useState, useRef } from "react";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import { Link } from "react-router-dom";
import CollectionTableTopBar from "./CollectionTableTopBar";
import UtilityService from "service/utility";
import { ListItemIcon, MenuItem } from "@mui/material";
import { Download, Print, Paid, Delete } from "@mui/icons-material";
import PaymentDialog from "components/Modals/CreatePaymentModal";
import DeleteCollectionInvoiceModel from "components/Modals/DeleteCollectionInvoiceModel";

const Table = (props) => {
  const { data } = props;

  const editingRow = useRef(null);
  const [isChangeModalOpen, setChangeModalOpen] = useState(false);

  const handleDeleteCollectionInvoice = () => {
    //logic for delete 
    setChangeModalOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        id: "collectionId",
        header: "#",
        size: 250,
        Cell: ({ cell }) => (
          <Link className="text-dark text-underline" to={"/collections/" + cell.getValue()}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorFn: (originalRow) => (originalRow.created ? new Date(originalRow.created) : ""),
        header: "تاريخ الإنشاء",
        size: 250,
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue().toLocaleDateString() : ""),
      },
      {
        accessorKey: "totalCollections",
        header: "التحصيل",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "totalCost",
        header: "التوصيل",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "amount",
        header: "تحصيل التاجر",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "status",
        header: "الحالة",
        size: 200,
        Cell: ({ cell }) => UtilityService.renderStatusMessage("COLLECTION", cell.getValue()),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    columnResizeDirection: "rtl",
    enableRowSelection: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    enableSorting: false,
    enablePagination: true,
    positionToolbarAlertBanner: "head-overlay",
    enableColumnFilters: false,

    renderRowActionMenuItems: ({ closeMenu, row }) => [

      <MenuItem
        key={0}
        onClick={() => {
          setChangeModalOpen(true);
          editingRow.current = { row, key: 0 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        حذف
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          setChangeModalOpen(true);
          editingRow.current = { row, key: 1 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Paid />
        </ListItemIcon>
        دفع
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {

        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Print />
        </ListItemIcon>
        طباعة
      </MenuItem>,
      <MenuItem
        key={3}
        onClick={() => {

        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        تنزيل pdf
      </MenuItem>,
      <MenuItem
        key={4}
        onClick={() => {

        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        تنزيل اكسل
      </MenuItem>,

    ],
    renderTopToolbar: CollectionTableTopBar,
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <PaymentDialog
        show={isChangeModalOpen && editingRow.current.key === 1}
        onHide={() => setChangeModalOpen(false)}
      />
      {editingRow.current && (
        <DeleteCollectionInvoiceModel
          isOpen={isChangeModalOpen && editingRow.current.key === 0}
          onClose={() => setChangeModalOpen(false)}
          onConfirmDeletion={handleDeleteCollectionInvoice}
          collectionInvoiceId={editingRow.current.row.original.id}
        />
      )};
    </>
  );
};

const CollectionTable = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CollectionTable;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import OrderService from "service/order";
import DriverInfo from "./DriverInfo";
import OrderIInvoices from "./OrderIInvoices";
import OrderItems from "./OrderItems";
import OrganizationInfo from "./OrganizationInfo";
import Summary from "./Summary";

const OrderDetails = (props) => {
  const [order, setOrder] = useState({});
  const { id } = useParams();

  useEffect(() => {
    populateOrderDetails();
  }, [props]);

  const populateOrderDetails = () => {
    OrderService.getOrdersDetails(id).then((res) => setOrder(res.data));
  };

  const calculateOrder = () => {
    OrderService.calculateOrder(id).then(() => populateOrderDetails());
  };

  const closeOrder = () => {
    OrderService.closeOrder(id).then(() => populateOrderDetails());
  };

  const { organization, items } = order;

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">تفاصيل الطلب</h3>
                  </div>
                  <div className="col text-left d-flex justify-content-end align-items-center">
                    <i className="fas fa-redo mx-2" role="button" onClick={populateOrderDetails}></i>
                    <button type="button" className="btn btn-info btn-sm  mx-2" onClick={calculateOrder}>
                      اعادة احتساب
                    </button>
                    {order.status === "N" && (
                      <button type="button" className="btn btn-danger btn-sm  mx-2" onClick={closeOrder}>
                        اغلاق الطلب
                      </button>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {order && (
                  <Row className="m-0">
                    <Col lg="4" className="p-4">
                      <OrganizationInfo organization={organization} />
                    </Col>
                    <Col lg="4" className="p-4">
                      <Summary order={order} />
                    </Col>
                    <Col lg="4" className="p-4">
                      <DriverInfo order={order} />
                    </Col>

                    <Col lg="12" className="p-4">
                      <OrderItems
                        items={items}
                        allowToAddPackage={order.status === "N"}
                        onRefresh={populateOrderDetails}
                        orderId={order.id}
                      />
                    </Col>
                    <Col lg="12" className="p-4">
                      <OrderIInvoices order={order} onRefresh={populateOrderDetails} />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderDetails;

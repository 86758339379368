import React from "react";
import { Button, Modal } from "reactstrap";
import PackageService from "service/package";
import UtilityService from "service/utility";

class SelectPackageModal extends React.Component {
  state = {
    isOpen: false,
    packageList: [],
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      packageList: [],
    });
  };

  openModal = (e) => {
    this.setState({
      isOpen: true,
    });
    this.populatePackageList();
  };

  populatePackageList = () => {
    PackageService.getPackagesList().then((res) => {
      this.setState({ packageList: res.data });
    });
  };

  onPackageSelected = (p) => {
    this.props?.onPackageSelected(p.id);
    this.closeModal();
  };

  render() {
    return (
      <>
        <Button color="success" type="button" size="sm" onClick={(e) => this.openModal(e)}>
          اظافة طرد
        </Button>
        <Modal
          className="modal-dialog-centered modal-dialog-scrollable"
          isOpen={this.state.isOpen}
          toggle={() => this.closeModal()}>
          <div className="modal-header">
            <h3 className="modal-title">احتيار طرد</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-0">
            <ul className="list-group p-0">
              {this.state.packageList.map((p) => {
                return (
                  <a
                    href="#"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    onClick={() => this.onPackageSelected(p)}>
                    <div>
                      <p className="m-0 font-weight-bold">{UtilityService.formatPackageId(p.id)}</p>
                      <p className="m-0">{p.description}</p>
                    </div>
                    <div>{UtilityService.formatPrice(p.price)}</div>
                  </a>
                );
              })}
            </ul>
          </div>
        </Modal>
      </>
    );
  }
}

export default SelectPackageModal;

import { Card, CardBody, CardHeader, Row } from "reactstrap";
import UtilityService from "service/utility";

const Summary = (props) => {
  const { totalCollections, totalCost, amount } = props;

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">الملخص</h3>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div>
            <p className="m-1 d-flex justify-content-between font-weight-bold">
              <span> التحصيل:</span>
              <span>{UtilityService.formatPrice(totalCollections) || 0.0}</span>
            </p>
            <p className="m-1 d-flex justify-content-between font-weight-bold">
              <span>التوصيل :</span>
              <span>{UtilityService.formatPrice(totalCost) || 0.0}</span>
            </p>
            <p className="m-1 d-flex justify-content-between font-weight-bold">
              <span> تحصيل التاجر:</span>
              <span>{UtilityService.formatPrice(amount) || 0.0}</span>
            </p>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Summary;

import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

const OrganizationInfo = (props) => {
  const { organization } = props;
  const branch = organization?.branch;

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">معلومات التاجر</h3>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          {organization && branch && (
            <div>
              <p className="m-1 d-flex justify-content-between font-weight-bold">
                <span> اسم التاجر:</span>
                <span>{organization.name}</span>
              </p>
              <p className="m-1 d-flex justify-content-between font-weight-bold">
                <span>الفرع:</span>
                <span>{branch.name}</span>
              </p>
              <p className="m-1 d-flex justify-content-between font-weight-bold">
                <span>هاتف:</span>
                <span>{branch.phone}</span>
              </p>
              <p className="m-1 d-flex justify-content-between font-weight-bold">
                <span>العنوان:</span>
                <span>{branch.address}</span>
              </p>
            </div>
          )}
          {(!organization || !branch) && <p>لا يوجد بيانات</p>}
        </CardBody>
      </Card>
    </>
  );
};

export default OrganizationInfo;

import SelectPackageModal from "components/Modals/SelectPackageModal";
import UserDisplay from "components/UserDisplay";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";
import OrderService from "service/order";
import UtilityService from "service/utility";

const OrderItems = (props) => {
  const { items, onRefresh, allowToAddPackage, orderId } = props;

  const handleAddingNewPackage = (packageId) => {
    OrderService.addPackageToOrder(orderId, packageId).then(() => onRefresh());
  };

  const handleDeleteOrderItem = (orderItemId) => {
    OrderService.deleteOrderItem(orderItemId).then(() => onRefresh());
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">الطرود</h3>
            </div>
            <div className="col text-left d-flex justify-content-end align-items-center">
              {allowToAddPackage && <SelectPackageModal onPackageSelected={handleAddingNewPackage} />}
            </div>
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="m-0">
            <Table className="align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th className="px-4">رقم الطرد</th>
                  <th>الزبون</th>
                  <th>الحالة</th>
                  <th>تاريخ الادخال</th>
                  <th>التفاصيل</th>
                  <th>تحصيل التاجر</th>
                  <th>التوصيل</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {items &&
                  items.map(function (item, i) {
                    const apackage = item.package;
                    return (
                      <tr key={item.id}>
                        <td className="px-4">
                          <Link className="text-dark text-underline" to={"/packages/" + item.packageId}>
                            {UtilityService.formatPackageId(item.packageId)}
                          </Link>
                        </td>
                        <td>
                          <UserDisplay user={apackage?.customer} address={apackage?.address} />
                        </td>
                        <td>{UtilityService.renderStatusMessage("PACKAGE", apackage?.status)}</td>
                        <td>{UtilityService.formatTimestamp(apackage?.created)}</td>
                        <td>{apackage?.description}</td>
                        <td>{UtilityService.formatPrice(apackage?.price)}</td>
                        <td>{UtilityService.formatPrice(apackage?.cost)}</td>
                        <td>
                          <i
                            className="fas fa-trash text-danger"
                            role="button"
                            onClick={() => handleDeleteOrderItem(item.id)}></i>
                        </td>
                      </tr>
                    );
                  })}
                {(!items || items.length == 0) && (
                  <tr>
                    <td col="8" className="px-4">
                      لم يتم العثور على اي طرود نشطة
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default OrderItems;
